
import { UpdateUserDto, User } from "@/store/user/types";
import { Component, Vue } from "vue-property-decorator";
import { Factcheck } from "@/types";
import { GetFactCheckArticlesFromUser } from "@/services/FactCheckService";
import { ROOT_ACTIONS } from "@/store/actions";

import * as UserServices from "@/services/UserServices";
import { auth } from "@/firebase";

@Component({
  components: {
    ConfirmationDialog: () =>
      import("@/components/dialogs/ConfirmationDialog.vue"),
  },
})
export default class SingleUserOverview extends Vue {
  loaded = false;
  user = {} as User;

  userCopy = {} as UpdateUserDto;

  factchecks = [] as Factcheck[];

  loading = {
    update: false,
    delete: false,
  };

  dialogs = {
    delete: false,
  };

  passwordConfirm = "";

  get userId(): string {
    return this.$store.state.user.profile.id;
  }

  async mounted() {
    await this.fetchUser();
    await this.fetchArticlesFromUser();
    this.loaded = true;
  }

  async revokeSessions() {
    try {
      const isMe = this.userId === this.user.id;
      await UserServices.RevokeSessions(this.user.id);
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Sessies verwijderd",
      });

      if (isMe) {
        await auth()?.signOut();
        location.reload();
      }
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    }
  }

  async fetchUser() {
    try {
      const _u = await UserServices.GetUser(this.$route.params.userId);
      if (_u) {
        this.user = _u;
        this.userCopy = {
          ..._u,
          password: "",
        };
      } else {
        this.$router.go(-1);
      }
    } catch (err) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    }
  }

  async fetchArticlesFromUser() {
    try {
      this.factchecks = await GetFactCheckArticlesFromUser(
        this.$route.params.userId
      );
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    }
  }

  async updateUser() {
    try {
      this.loading.update = true;

      if (
        this.userCopy.password.length > 0 &&
        this.userCopy.password !== this.passwordConfirm
      ) {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: "error",
          text: "Paswoorden komen niet overeen",
        });
        return;
      }

      await UserServices.UpdateUser(this.userCopy);

      if (this.userId === this.user?.id) {
        if (
          this.userCopy.email !== this.user.email ||
          this.userCopy.password.length > 0
        ) {
          await auth()?.signOut();
          location.reload();
          this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: "success",
            text: "Gelieve opnieuw aan te melden",
          });
        }
        return;
      }

      await this.fetchUser();
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Gebruiker geupdate",
      });
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    } finally {
      this.loading.update = false;
    }
  }

  async deleteUser() {
    try {
      this.loading.delete = true;
      await UserServices.DeleteUser(this.userCopy.id);
      this.$router.push("/accounts");
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Gebruiker verwijderd",
      });
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    } finally {
      this.loading.delete = false;
    }
  }
}
